import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Particles from 'react-tsparticles'
import config from '../../assets/lib/particles-recruit.json'

const ProjectManagerPageContent = () => (
  <div class="main">
    <section class="section">
      <div class="section__inner section__inner--recruit">
      <ul class="recruit-content">
        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">募集要項</h2>
          </div>
          <div class="recruit-content__table">
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">職種</div>
              <div class="recruit-content__cell">プロジェクトマネージャー</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務場所</div>
              <div class="recruit-content__cell">遠隔</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務形態</div>
              <div class="recruit-content__cell"> フルタイム・パートタイムどちらも可能（週20時間以上）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">契約形態</div>
              <div class="recruit-content__cell">雇用契約（社員）・業務委託契約どちらも可能</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">給与</div>
              <div class="recruit-content__cell">50万〜100万/月（※フルタイムの場合の金額）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">必要なスキル等</div>
              <div class="recruit-content__cell">後述</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">試用期間</div>
              <div class="recruit-content__cell">3ヶ月</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">特記事項</div>
              <div class="recruit-content__cell">保険完備、副業可</div>
            </div>
          </div>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">業務内容</h2>
          </div>
          <h3 class="recruit-content__title-sub">受託開発案件のプロジェクト管理</h3>
          <ul class="recruit-content__body">
            <li>開発実績もご参照下さい（webサービスが多いです）</li>
            <li>パートナー企業とのやり取りも含みます</li>
          </ul>

          <h3 class="recruit-content__title-sub">プロジェクト間のリソースの調整</h3>
          <h3 class="recruit-content__title-sub">開発メンバーのサポート・コーチング</h3>
          <ul class="recruit-content__body">
            <li>本人の方向性・適性を考慮したプロジェクト・タスクの割り振り</li>
            <li>詰まっている場合などに面談・アドバイス</li>
            <li>（可能であれば）技術面でのサポート</li>
          </ul>
          <h3 class="recruit-content__title-sub">社内でのプロジェクト管理の標準策定</h3>
          <h3 class="recruit-content__title-sub">（希望する場合）開発業務</h3>
          <ul class="recruit-content__body">
            <li>プレイングマネージャーとして働いた方が、最新の技術に付いていくのは比較的容易だと思います</li>
            <li>優秀な開発メンバーと一緒に働く事が出来ます</li>
          </ul>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">必須の条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">１．以下のいずれかに興味があること</h3>
          <ul class="recruit-content__body">
            <li>プロジェクト管理業務</li>
            <li>弊社、あるいは弊社の業務</li>
          </ul>
          <h3 class="recruit-content__title-sub">２．プロジェクト管理に関する知識・経験として、以下のいずれか</h3>
          <ul class="recruit-content__body">
            <li>ソフトウェア開発プロジェクトの管理経験1年以上（管理手法は問わず）</li>
            <li>PMP、プロジェクトマネージャー試験、Certified ScrumMaster 等の有資格者</li>
          </ul>
          <h3 class="recruit-content__title-sub">３．ソフトウェア開発に関する知識・経験として、以下のいずれか</h3>
          <ul class="recruit-content__body">
            <li>ソフトウェア開発実務経験3年以上（言語等は問いません）</li>
            <li>コンピューターサイエンス・情報系の大学卒業</li>
            <li>プログラム・情報系の高専、あるいは専門学校卒業</li>
          </ul>
          <p class="recruit-content__annotation">※1〜3は and 条件です。</p>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">望ましい条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">英語力</h3>
          <ul class="recruit-content__body">
            <li>外国の方とやり取りすることもあります</li>
            <li>今後、オフショア開発を増やす予定です</li>
            <a class="recruit-content__link" href="https://blog.mobalab.net/2018/08/20/will-use-offshore-development-more/" target="_blank">オフショア開発に本格的に取り組みます – もばらぶん</a>
          </ul>
          <h3 class="recruit-content__title-sub">技術力</h3>
          <ul class="recruit-content__body">
            <li>必須ではありませんが、プレイングマネージャーとして勤務する場合は重要となります</li>
          </ul>
          <h3 class="recruit-content__title-sub">新しい技術・仕事の進め方などへの興味</h3>
          <ul class="recruit-content__body">
            <li>常により良い仕事の進め方を追求しているので、変化に柔軟に対応できる方だと嬉しいです</li>
          </ul>
        </li>
      </ul>

      </div>
    </section>
    <section class="section section--gray">
      <div class="recruit-content__buttons">
        <h3 class="recruit-content__buttons-annotation">「話を聞いてみたい」程度の方も含め、ご興味のある方はまずはご連絡ください。</h3>
        <a href="https://blog.mobalab.net/jobs/job-application-form/" className="btn-blue">採用応募フォーム</a>
        <a href="/recruit/" class="btn-back-to-recruit-list">採用情報一覧に戻る</a>
      </div>
    </section>
  </div>
)

const ProjectManagerPage = (props) => (
  <Layout
    title="採用情報・プロジェクトマネージャー（フルリモート） | 株式会社もばらぶ"
    description="株式会社もばらぶでは、現在フルリモートでのプロジェクトマネージャーを募集しています。ウェブ関連の技術・経験なども活かせるお仕事ですので、ご興味のある方はお気軽にご応募ください。"
    pathName={props.location.pathname}
  >
    <Header />
    <div class="hero hero--job">
      <Particles className="hero__background hero__background--job" params={config}/>
      <div class="hero__title-area">
        <p class="hero__title-ja hero__title-ja--job">採用情報</p>
        <h1 class="hero__title hero__title--job">プロジェクトマネージャー</h1>
      </div>
    </div>
    <ProjectManagerPageContent />
  </Layout>
)
export default ProjectManagerPage